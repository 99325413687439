import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Heroo from './Components/Home/Heroo';
import Loginn from './Components/LogIn/Loginn';
import SignUpp from './Components/SignUp/SignUpp';
import Recruiters from './Components/RECRUITERS/Recruiters';
import Dashhboard from './Components/Dashboardd/Dashhboard';
import Validationn from './Components/Validation/Validationn';
import Aboutt from './Components/About_Us/Aboutt';
import Jobseeker from './Components/JOBSEEKER/Jobseeker';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
    <Route path='/' element={<Heroo/>}/>
    <Route path='/Loginnn' element={<Loginn/>}/>
    <Route path='/Signuppp' element={<SignUpp/>}/>
    <Route path='/Recruiterr' element={<Recruiters/>}/>
    <Route path='/Dashboard' element={<Dashhboard/>}/>
    <Route path='/Validationnn' element={<Validationn/>}/>
    <Route path='/Abouttt' element={<Aboutt/>}/>
    <Route path='/Jobseeker' element={<Jobseeker/>}/>
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
