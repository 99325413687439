import Footer from "../../Footer"
import Header from "../../Header"
import FAQs from "./Recruiterinfo"
import Recruiter from "./Recruiter"


function Recruiters() {
  return (
    <>
    <Header/>
    <Recruiter/>
    <FAQs/>
    <Footer/>
    </>
  )
}

export default Recruiters
