import './Aboutt.css';

function Aboutt() {
  return (
    <>
      
    </>
  )
}

export default Aboutt
