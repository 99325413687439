import './Work.css';

function Work() {
  return (
    <>
     <div className="hello">
            <div className="part2" id="how">
                <div className="part2-left">
                    <h2>How Does it Work?</h2>
                    <p>"Be so good they can't ignore you"</p>
                </div>
                <div className="part2-right">
                    <p className="one step">Our Vision is to empower people by giving them the opportunity they need to make right decision.</p>
                    <p className="two step">Your talent and knowledge determines what you can do to make you unstopable.</p>
                </div>
            </div>
    </div>
    </>
  )
}

export default Work
