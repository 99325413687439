import Dashboard from "../../Dashboard"
import Signup from "./Signup"

function SignUpp() {
  return (
    <>
      <Signup/>
    </>
  )
}

export default SignUpp
