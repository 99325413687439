import './Footer.css';
import logoo from './Image/Careerbountyimg.png';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

function Footer() {
  const scrollTop = () =>{
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
}
  return (
  <div className="footer-area footer--light">
  <div className="footer-big">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <div className="footer-widget">
            <div className="widget-about">
              <img src={logoo} alt="logo" className="img-fluid"/>
              <p>Career Bounty is a way to connect the talents to the right hands</p>
              <ul className="contact-details">
                <li>
                  <span className="icon-earphones"></span> Call Us :
                  <a href="tel:344-755-111">+91-9029060930</a>
                </li>
                <li>
                  <span className="icon-envelope-open"></span>
                  <a href="mailto:support@aazztech.com">connect@careerbounty.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-sm-4">
          <div className="footer-widget">
            <div className="footer-menu footer-menu--1">
              <h4 className="footer-widget-title classbold"><b>Popular Category</b></h4>
              <ul>
                <li>
                <Nav.Link as={Link} to="/" onClick={scrollTop}><a>HOME</a></Nav.Link>
                </li>
                <li>
                <Nav.Link as={Link} to="/Recruiterr" onClick={scrollTop}><a href="/">RECRUITERS</a></Nav.Link>
                </li>
                <li>
                <Nav.Link as={Link} to="/Jobseeker" onClick={scrollTop}><a>JOB SEEKERS</a></Nav.Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-sm-4">
          <div className="footer-widget">
            <div className="footer-menu">
              <h4 className="footer-widget-title classbold"><b>Our Company</b></h4>
              <ul>
                <li>
                <NavLink as={Link} to="/Abouttt"><a href="/">About Us</a></NavLink>
                </li>
                <li>
                  <a>Testimonials</a>
                </li>
                <li>
                  <a>Contact Us</a>
                </li>
                <li>
                  <a>Blog</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
   
        <div className="col-md-3 col-sm-4">
          <div className="footer-widget">
            <div className="footer-menu no-padding">
              <h4 className="footer-widget-title classbold"><b>Useful Links</b></h4>
              <ul>
                <li>
                  <a>Terms &amp; Conditions</a>
                </li>
                <li>
                  <a>Refund Policy</a>
                </li>
                <li>
                  <a>FAQs</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
  <div className="mini-footer">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="copyright-text">
            <p>© 2023
              <a href="/">Career Bounty</a>. All rights reserved.
            </p>
          </div>

          <div className="go_top">
            <span className="icon-arrow-up"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default Footer
