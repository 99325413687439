import './Search.scss';


function Search() {
  return (
    <>
     <div className="container-search-job bg-white">
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
                <div className="search-job">
                    <h2 className="search-job-title">FIND YOUR <span>DREAM</span> JOB</h2>

                    <div className="search-job-form">
                        <form action="" method="get" _lpchecked="1">
                            <div className="row no-gutters">
                                <div className="col-12 col-md-4">
                                    <div className="search-job-form-field first">
                                        <label for="searchJob" className="search-job-form-field-label"></label>
                                        <input type="text" id="searchJob" className="search-job-form-field" placeholder="eg. WEB DESIGNER"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="search-job-form-field">
                                        <label for="searchCat" className="search-job-form-field-label"></label>
                                        <input name="" id="searchCat" className="search-job-form-field"placeholder='ALL CATEGORIES'>
                                            
                                            {/* <option value="0">ALL CATEGORIES</option> */}
                                            {/* <option value="0">Web</option>
                                            <option value="0">Application</option>
                                            <option value="0">AR</option> */}
                                        </input>
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="search-job-form-field">
                                        <label for="searchWhere" className="search-job-form-field-label"></label>
                                        <input type="text" id="searchWhere" className="search-job-form-field" placeholder="LOCATION"/>
                                    </div>
                                </div>
                                <div className="col-12 col-md-2">
                                    <button type="submit" className="search-job-form-button">SEARCH JOBS</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
    </>
  )
}

export default Search
