import './Validation.css';
import CloseIcon from '@mui/icons-material/Close';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import React, { useState,  useEffect } from 'react';
import otppimg from '../../Image/OTPimgg.jpg';
import axios from 'axios';

function Validation() {
    const location = useNavigate("");
    const [otp, setOTP] = useState('');

    // useEffect(() => {
    //   const serverOtp = localStorage.getItem('OTP');
    //   if (serverOtp) {
    //     setOTP(serverOtp);
    //   }
    // }, []);

    const handleChange = (e) => {
      setOTP(e.target.value);
    };
  
    // Update the handleSubmit function
    const handleSubmit = (e) => {
      e.preventDefault();
      // const sendData = {
      //   userOTP: otp,
      // };

      const enteredOTP = String(otp);
      var serverOtp=localStorage.getItem('OTP');

      if (enteredOTP===serverOtp) {
        localStorage.removeItem('OTP')
        alert('OTP Verified')
        location('/Dashboard')
      } else {
        alert("InValid OTP...! ")
      }
    }
    
    async function requestSMSPermission() {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'sms' });
        if (permissionStatus.state === 'granted') {
          // Permission already granted
          // Proceed to read OTP
        } else if (permissionStatus.state === 'prompt') {
          // Permission not yet granted, show permission prompt
          await permissionStatus.request();
          // Proceed to read OTP if permission is granted
        } else {
          // Permission denied
          // Handle the error or show an error message
        }
      } catch (error) {
        // Handle any errors that occur during the permission request
      }
    }
  
        /*===== FOCUS =====*/
    const inputs = document.querySelectorAll(".form__input")
    
    /*=== Add focus ===*/
    function addfocus(){
        let parent = this.parentNode.parentNode
        parent.classList.add("focus")
    }
    
    /*=== Remove focus ===*/
    function remfocus(){
        let parent = this.parentNode.parentNode
        if(this.value == ""){
            parent.classList.remove("focus")
        }
    }
    
    /*=== To call function===*/
    inputs.forEach(input=>{
        input.addEventListener("focus",addfocus)
        input.addEventListener("blur",remfocus)
    })
  
  return (
    <>
     <Nav.Link as={Link} to="/" className='crossbutton'><CloseIcon/></Nav.Link>
        <div className="l-form">
            {/* <div className="shape1"></div>
            <div className="shape2"></div> */}

            <div class="form">
                <img src={otppimg} alt="" class="form__img" />

                <form onSubmit={handleSubmit} className="form__content">
                    <h1 class="form__title">Welcome</h1>

                    <div className="form__div form__div-one">
                        <div className="form__icon">
                            <i className='bx bx-phone'></i>
                        </div>

                        <div className="form__div-input">
                            <label htmlFor="" className="form__label"></label>
                            <input type="number" className="form__input" placeholder='OTP' name='otp' required="Required" value={otp} onChange={handleChange}/>
                        </div>
                    </div>

                    <input type="submit" className="form__button" value="Verify" required/>

                    
                </form>
            </div>
        </div>
    </>
  )
}

export default Validation
