import './Header.css';
import { Link, NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from './Image/Careerbountyimg.png';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import HRimageheader from '../src/Image/HewHR.jpg';
import Employeeimage from '../src/Image/Employee.jpg';
import { Grid } from '@mui/material';


function Header()
{
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <>
<nav className="navbar navbar-expand-lg navbar-light fixed-top">
  <div className="container-fluid">
    <a className="navbar-brand" href="/"><img src={logo} alt='logo' className='logoclass'></img></a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
        <li className="nav-item">
        <Nav.Link as={Link} to="/" className='Homeclass'><a className="nav-link" aria-current="page">HOME</a></Nav.Link>
        </li>
        <li className="nav-item">
        <Nav.Link as={Link} to="/Recruiterr" className='Homeclass'><a className="nav-link">RECRUITERS</a></Nav.Link>
        </li>
        <li className="nav-item">
        <Nav.Link as={Link} to="/Jobseeker"className='Homeclass'><a className="nav-link">JOB SEEKERS</a></Nav.Link>
        </li>
      </ul>
      
      <ul>
      <NavLink as={Link} to="/Loginnn"><button className="buttonnn" type="submit">LogIn</button></NavLink>
      </ul>
      
      <ul>
      {/* <NavLink as={Link} to="/Signuppp"></NavLink> */}
       <button className="buttonn" type="button" onClick={togglePopup}>SignUp</button>
      </ul>
    </div>
  </div>
</nav>
          {/* Signup popup */}
      <Modal show={showPopup} onHide={togglePopup} className="custom-modal">
        {/* <Modal.Header closeButton>
          <Modal.Title>Are You A ?</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
        <Modal.Title>Are You A ?</Modal.Title>
          <div className='imagee'>
          <NavLink as={Link} to="/Signuppp">
             <img src={HRimageheader} alt="logo" ></img>
             <h2 className="imagee-label">Employer</h2>
             </NavLink>
          </div>

          <div className='imagee2'>
          <NavLink as={Link} to="/Signuppp">
             <img src={Employeeimage} alt="logo"></img>
             <h2 className="imagee-label">CareerExplorer</h2>
             </NavLink>
          </div>
        
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Header
