import './Hero.scss';
import joobsearch from '../../Image/imgggjobsearch.png';
import { Link, NavLink } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import HRimageheader from '../../Image/HewHR.jpg';
import Employeeimage from '../../Image/Employee.jpg';
import { Grid } from '@mui/material';

function Hero() 
{
 const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  return (
    <>
<section>
<div className="circle"></div>
<div className="content">
<div className="textBox">
<h2>"Be So Good they Can't<br/><span>Ignore You"</span></h2>
<p>Our Vision is to Empower People by giving them the Opportunity they need to make Right Decisions.</p>
{/* <NavLink as={Link} to="/Signuppp">Get Started</NavLink> */}
<button className="buttonn" type="button" onClick={togglePopup}>Get Started</button>
</div>

<div className="imgBox">
<img src={joobsearch} className="jobsearchimgg"/>
</div>
</div>

</section>
<Modal show={showPopup} onHide={togglePopup} className="custom-modal">
        {/* <Modal.Header closeButton>
          <Modal.Title>Are You A ?</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
        <Modal.Title>Are You A ?</Modal.Title>
          <div className='imagee'>
          <NavLink as={Link} to="/Signuppp">
             <img src={HRimageheader} alt="logo" ></img>
             <h2 className="imagee-label">Employer</h2>
             </NavLink>
          </div>

          <div className='imagee2'>
          <NavLink as={Link} to="/Signuppp">
             <img src={Employeeimage} alt="logo"></img>
             <h2 className="imagee-label">CareerExplorer</h2>
             </NavLink>
          </div>
        
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Hero
