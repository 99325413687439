import Validation from "./Validation"


function Validationn() {
  return (
    <>
    <Validation/>
    </>
  )
}

export default Validationn
