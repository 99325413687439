import './Recruiterinfo.scss';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import HiringImage from '../../Image/hirenowimg.png';


function FAQs() {
  const scrollTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
  return (
    <>
      <div className="Recruiterdiv">
    <Grid container >
    <Grid item lg={6} md={6} xs={12} >
    <img src={HiringImage} alt='logo' className='HiringImageee'></img>
    </Grid>

    
    <Grid item lg={6} md={6} xs={12}>
    <div className='InfoRecruiterGridDiv'>
    <h2 className='InfoRecruiterGridHeadLine'><b>CareerBounty Website is for <span className='InfoRecruiterGridSpan'>Candidate and Hiring Managers.</span></b></h2>
    <p className='InfoRecruiterGridParagraph'>A targeted job portal for SMEs and Startups - CareerBounty is a platform that fulfills the hiring requirements of 1K+ verified recruiters. CareerBounty is a direct hiring website connects recruiters to 120K+ verified candidates who are searching for jobs in Indian startups. Moreover, CareerBounty allows for direct and private communication without the interference of consultants.</p>
    <div><button className='InfoRecruiterGridbutton'>Hire Now</button></div>
    </div>
    </Grid>
    </Grid>
    </div>
    </>
  )
}

export default FAQs
