import Footer from "../../Footer"
import Header from "../../Header"
import Hero from "./Hero"
import Home_slider_page from "./Home_slider_page"
import Search from "./Search"
import Work from "./Work"

function Heroo() {
  return (
    <>
    <Header/>
    <Hero/>
    <Search/>
    <Work/>
    <Home_slider_page/>
    <Footer/>
    </>
  )
}

export default Heroo
