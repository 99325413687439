import Login from "./Login"

function Loginn() {
  return (
    <>
     <Login/>
    </>
  )
}

export default Loginn
