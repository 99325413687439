import './Home_slider_page.scss'
import merlotvcindiaimg from '../../Image/MerlotNewLogoImageCo4.png'
import uponlutechimg from '../../Image/uponlytechh.png';
import loanlooomimg from '../../Image/loanloomimg.png';
import proppertyok from '../../Image/propertyokimg.png';

function Home_slider_page() {
  return (
    <>
  <div className="logo-slider">
  <h1 className='ourclient'><b>Our Clients</b></h1>
	<div className="logo-slide-track">
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
		<div className="slide">
			<img src={merlotvcindiaimg} alt="" />
		</div>
		<div className="slide">
			<img src={loanlooomimg} alt="" />
		</div>
		<div className="slide">
			<img src={uponlutechimg} alt="" />
		</div>
		<div className="slide">
			<img src={proppertyok} alt="" />
		</div>
	</div>
</div>

    </>
  )
}

export default Home_slider_page
