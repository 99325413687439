import Dashboard from "../../Dashboard"

function Dashhboard() {
  return (
    <>
      <Dashboard/>
    </>
  )
}

export default Dashhboard
