import './Login.css';
import CloseIcon from '@mui/icons-material/Close';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, Navigate } from 'react-router-dom';
import {GoogleLoginButton} from "react-social-login-buttons";
import {LoginSocialGoogle} from "reactjs-social-login";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


function Login() 
{
    const location = useNavigate("");
    // let history = useNavigate();
    const [data,setData]=useState
    ({
        Phone_number:""
    })

    const handleChange=(e)=>
    {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
        const formattedValue = numericValue.slice(0, 10); 
        setData({ ...data, Phone_number: formattedValue });
        setData({ ...data, [e.target.name]: e.target.value });
        console.log(data) 
    }

    const submitForm=(e)=>
    {
        e.preventDefault();
        if (data.Phone_number.length !== 10) 
        {
            alert('Phone number must be 10 digits');
            return;
        }

        const sendData = {
            mobile:data.Phone_number,
        }
        console.log(sendData);

        axios.post('http://www.careerbounty.com/CareerBounty/mobile_otp.php', {sendData})
        .then((response) => 
        {
            const sendottp = (response.data['OTP']);
            localStorage.setItem('OTP', sendottp);
            console.log(sendottp)
            location('/Validationnn')
        })
        .catch((error) => 
        {
            console.error(error);
        });
    };

    /*===== FOCUS =====*/
const inputs = document.querySelectorAll(".form__input")

/*=== Add focus ===*/
function addfocus(){
    let parent = this.parentNode.parentNode
    parent.classList.add("focus")
}

/*=== Remove focus ===*/
function remfocus(){
    let parent = this.parentNode.parentNode
    if(this.value == ""){
        parent.classList.remove("focus")
    }
}

/*=== To call function===*/
inputs.forEach(input=>{
    input.addEventListener("focus",addfocus)
    input.addEventListener("blur",remfocus)
})



  return (
    <>
    <Nav.Link as={Link} to="/" className='crossbutton'><CloseIcon/></Nav.Link>
        <div class="l-form">
            <div class="shape1"></div>
            <div class="shape2"></div>

            <div class="form">
                <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-form/eafbae66248bc27a0b1e26e2a5ddd2ad0d01956b/assets/img/authentication.svg" alt="" class="form__img" />

                <form  class="form__content" onSubmit={submitForm}>
                    <h1 class="form__title">Welcome</h1>

                    <div className="form__div form__div-one">
                        <div className="form__icon">
                            <i className='bx bx-phone'></i>
                        </div>

                        <div className="form__div-input">
                            <label htmlFor="" className="form__label"></label>
                            <input type="number" className="form__input" placeholder='Phone Number' name='Phone_number' required="Required" 
                            onChange={handleChange} value={data.Phone_number} />
                        </div>
                    </div>

                    <input type="submit" class="form__button"  value="Generate OTP" required/>


                    <LoginSocialGoogle
                    client_id={"93081273856-qahtl3rliq9900km7q9o4b7enphnur97.apps.googleusercontent.com"}
                    scope="openid profile email"
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    onResolve={({provider,data})=>{
                    console.log(provider,data)
                    }}
                    onReject={(err)=>{
                    console.log(err)
                    }}>

                    <GoogleLoginButton/>

                    </LoginSocialGoogle>

                </form>
            </div>
        </div>
    </>
  )
}

export default Login
