import Footer from "../../Footer"
import Header from "../../Header"
import Jobseekeer from "./Jobseekeer"

function Jobseeker() {
  return (
    <>
    <Header/>
    <Jobseekeer/>
    <Footer/>
    </>
  )
}

export default Jobseeker
