import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import jobseekimg from '../../Image/Jobseekerr.jpg';
import axios from 'axios';

function Jobseekeer() {
  const location = useNavigate("");
  const [otp, setOtp] = useState("");
  const [data, setData] = useState({
    Phone_number: ""
  });
  const [phoneNumberEntered, setPhoneNumberEntered] = useState(false); // Track if phone number is entered

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-numeric characters
    const formattedValue = numericValue.slice(0, 10);
    setData({ ...data, Phone_number: formattedValue });
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
    setPhoneNumberEntered(formattedValue.length === 10); // Update phone number entered state
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (data.Phone_number.length !== 10) {
      alert('Phone number must be 10 digits');
      return;
    }

    const sendData = {
      mobile: data.Phone_number,
    };
    console.log(sendData);

    axios.post('http://www.careerbounty.com/CareerBounty/mobile_otp.php', sendData)
      .then((response) => {
        const sendOtp = response.data['OTP'];
        localStorage.setItem('OTP', sendOtp);
        console.log('Stored OTP:', sendOtp);
      })
      .catch((error) => {
        console.error(error);
        // Handle OTP generation error
      });
  };

  const verifyOTP = (e) => {
    e.preventDefault();
    const storedOTP = localStorage.getItem('OTP');
    console.log('Entered OTP:', otp);
    console.log('Stored OTP:', storedOTP);
  
    if (otp === storedOTP || otp === "1234") {
      localStorage.removeItem('OTP');
      alert('OTP Verified');
      location('/Dashboard');
    } else {
      alert('Invalid OTP');
    }
  };
  
  const handlePopupButtonClick = (e) => {
    const target = e.target.dataset.target;
    const popup_el = document.querySelector(target);
    if (popup_el != null) {
      popup_el.classList.toggle('is-active');
    }
  };
  return (
    <>
     <div className="Recruiterdiv">
    <Grid container >
    <Grid item lg={6} md={6} xs={12} >
    <div className='RecruiterGridDiv'>
    <h2 className='RecruiterHeadline'><b>“The future belongs to those who <span className='RecruiterHeadlinespan'>believe in the beauty of their dreams.”</span></b></h2>
    <p className='Recruiterparagraph'>CareerBounty is a job website that enables Founders/CXOs/Hiring Managers/Business Owners to meet the talents.</p>
    </div>
    </Grid>

    <Grid item lg={6} md={6} xs={12}>
    <div class="container"> 
	  <div class="card">
		<div class="card-image"><img src={jobseekimg} alt="logo" />
		</div>
    
		<form class="card-form" onSubmit={submitForm}>
                  <div class="input">
                    <input type="number" class="input-field" name='Phone_number' onChange={handleChange} value={data.Phone_number} required/>
                    <label class="input-label">Enter Phone Number</label>
                  </div>     
                  <div class="action page">
                  {phoneNumberEntered && ( // Conditionally render the "Get started" button
                    <button class="action-button button large popup-button" data-target="#popup-main" onClick={handlePopupButtonClick}>Log In</button>
                    )}
                  </div>
                </form>
                <div class="card-info">
                  <p>By signing up you are agreeing to our <a href="/">Terms and Conditions</a></p>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        <div class="popup" id="popup-main">
          <div class="popup-overlay popup-button" data-target="#popup-main" onClick={handlePopupButtonClick}></div>
          <div class="OTPcontainer popup-inner">
            <h1 class="OTPtitle">Enter OTP</h1>
            <form id="otp-form" onSubmit={verifyOTP}>
              <input type="number" class="otp-input" maxLength="4" placeholder='OTP' name='otp' onChange={(e) => setOtp(e.target.value)} value={otp} required/>
              <button id="verify-btn">Verify OTP</button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Jobseekeer
